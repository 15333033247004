<template >
  <div class="row">
    <div
      class="col-6 col-md-4 col-lg-3 mb-4"
      v-for="(item, index) in newItems"
      :key="index"
    >
      <div class="newCard border"> 
        <img
          v-if="item.imLikedThis"
          src="/images/unlike.svg"
          class="like"
          @click="$emit('addLike', item)"
          :alt="$getByLang(item.name)"
        />
        <img
          v-else
          src="/images/like.svg"
          class="like"
          @click="$emit('addLike', item)"
          :alt="$getByLang(item.name)"
        />
        <span v-if="item.hasOffer == true" class="itemOfferRight">
                      {{ $t("rival") }}
                      {{ item.offer.offerValue }}%
                    </span>

                    <!-- <span v-if="item.hasOffer == true" class="">
                      {{ $t("rival") }}
                      {{ item.offer.name.ar }}
                    </span> -->

        <img
          class="logo"
          :src="
            item.colorsList.length > 0 &&
            item.colorsList[0].colorsImage.length > 0
              ? $baseUploadURL + item.colorsList[0].colorsImage[0].name
              : ''
          "
          :alt="$getByLang(item.name)"
          style="cursor: pointer"
          @click="$router.push('/product/' + item._id)"
        />
        <div
          class="newCard-body px-3 pb-3 border-top pt-2"
          style="cursor: pointer"
          @click="$router.push('/product/' + item._id)"
        >
          <h5 class="text-truncate">
            {{ $textSlice($getByLang(item.name), 40) }}
          </h5>
          <div class="d-flex align-items-center gap-2">
            <b-form-rating
              v-model="item.rate"
              variant="warning"
              readonly
              inline
              no-border
              size="sm"
              :locale="locale"
            ></b-form-rating>
            <span> ({{ item.rateAmount }}) </span>
          </div>

          <!-- <p v-if="item.hasOffer == false" class="mb-2">
            {{ item.sizes.length > 0 ? item.sizes[0].mainPrice : 0 }}
            {{ $t("r.s") }}
          </p> -->

         <div class="price-container" v-if="item.sizes.length > 0">
            <p class="current-price" :class="{ hidden: !item.hasOffer }">
              {{
                (item.sizes[0].mainPrice - 
                item.sizes[0].mainPrice * (item.hasOffer ? item.offer.offerValue / 100 : 0)).toFixed(2) 
              }}
              {{ $t("r.s") }}
            </p>
            <p v-if="item.hasOffer" class="old-price">
              <del>
                {{ item.sizes[0].mainPrice }} {{ $t("r.s") }}
              </del>
            </p>
            <p v-else class="single-price">
              {{ item.sizes[0].mainPrice }} {{ $t("r.s") }}
            </p>
          </div>
          
          <!-- <p v-if="item.hasOffer == true && item.sizes.length > 0">
                        {{
                          (item.sizes[0].mainPrice -
                          item.sizes[0].mainPrice * (item.offer.offerValue / 100)).toFixed(2)
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                           {{ item.sizes.length > 0 ? item.sizes[0].mainPrice : 0 }}
                          {{ $t("r.s") }}
                        </del>
                      </p> -->
        </div>
      </div>
    </div>

    <div class="text-center">
      <router-link to="/search" class="search__link">
        {{ $t("watch more") }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    newItems: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
      default: "en",
    },
  },
};
</script>
<style lang="scss" scoped>
.search__link {
  background-color: #ffeece;
  color: #f59f00;
  display: block;
  width: fit-content;
  padding: 8px 16px;
  margin: 8px auto;
  text-decoration: underline;
  border-radius: 8px;
}
.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 50px; /* تحديد ارتفاع أدنى لضمان الاتساق */
}

.current-price.hidden {
  visibility: hidden; /* إخفاء السعر العلوي بدون إزالة مكانه */
}

.current-price {
  font-size: 24px; /* أو الحجم الذي تراه مناسبًا */
  font-weight: bold;
  color: #000; /* اللون الذي تريده للنص */
  margin: 0;
}

.single-price {
  font-size: 24px; /* أو الحجم الذي تراه مناسبًا */
  font-weight: bold;
  color: #000; /* اللون الذي تريده للنص */
  margin: 0;
}

.old-price {
  font-size: 18px; /* أو الحجم الذي تراه مناسبًا */
  color: #888; /* اللون الذي تريده للنص المشطوب */
  margin: 0;
}

.old-price del {
  color: #888; /* اللون الذي تريده للنص المشطوب */
}
</style>
<style scoped>

</style>
