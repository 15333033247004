<template>
  <div>
    <section class="container header">
      <div
        id="carouselExampleFade"
        class="carousel slide mt-3"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            v-for="(item, index) in sliderList"
            :key="index"
            class="carousel-item active"
            :class="index == 0 ? 'active' : ''"
          >
            <a v-if="$root.$i18n.locale == 'ar'" :href="item.link" target="_blank">
              <img
                :src="$baseUploadURL + item.imageAr"
                class="d-block w-100 animate__animated animate__tada"
                alt="1"
              />
            </a>
            <a v-if="$root.$i18n.locale == 'en'" :href="item.link" target="_blank">
              <img
                :src="$baseUploadURL + item.imageEn"
                class="d-block w-100 animate__animated animate__tada"
                alt="1"
              />
            </a>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <button class="carousel-arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :width="isTab() ? 15 : 30"
              :height="isTab() ? 15 : 30"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
          </button>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <button class="carousel-arrows">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              :width="isTab() ? 15 : 30"
              :height="isTab() ? 15 : 30"
              fill="currentColor"
              class="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>

    <category-slider :products="catList" />
    <!-- <section class="container categories categories2 mt-5 mb-3 mb-md-5">
      <h3>{{ $t("categories") }}</h3>
      <div ref="swiper" class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) of catList"
            :key="index"
          >
            <div class="card p-2">
              <img
                :src="$baseUploadURL + item.categoryImage"
                class="card-img-top"
                :alt="$getByLang(item.name)"
                @click="setSubCat(item._id)"
              />
              <div
                class="d-flex align-item-center justify-content-between mt-3"
              >
                <h5 class="card-title" style="cursor: pointer">
                  {{ $getByLang(item.name) }}
                </h5>
                <span>
                  <svg
                    class="dg180"
                    width="34"
                    height="24"
                    viewBox="0 0 34 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="cursor: pointer"
                  >
                    <path
                      d="M0.939339 10.9393C0.353554 11.5251 0.353554 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939339 10.9393ZM34 10.5L2 10.5V13.5L34 13.5V10.5Z"
                      fill="#797979"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button">
          <div class="xsd-button-next">
            <img src="../assets/images/next.svg" alt="" class="dg180" />
          </div>
          <div class="xsd-button-prev">
            <img src="../assets/images/prev.svg" alt="" class="dg180" />
          </div>
        </div>
      </div>
    </section> -->
    <offers-slider v-if="offersList.length > 0" :offers="offersList" />

    <!-- <section
      v-if="offersList.length > 0"
      class="ooffers mt-5 mb-3 mb-md-5 container"
    >
      <h3 class="mb-3 mb-md-5">
        {{ $t("Latest offers") }}
      </h3>

      <div ref="newOffers" class="swiper">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) of offersList"
            :key="index"
            class="swiper-slide border"
          >
            <div class="position-relative">
              <div class="overlayy">
                <div class="con" @click="$router.push('/offers/' + item._id)">
                  <p
                    @click="$router.push('/offers/' + item._id)"
                    style="cursor: pointer"
                    class="d-flex align-items-start flex-column"
                  >
                    <span> {{ item.value }}% OFF</span>
                    <span>
                      {{ $t("end") }} {{ $formatDateOffers(item.toDate) }}
                    </span>
                  </p>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>
                      {{ $getByLang(item.name) }}
                    </span>
                    <button
                      @click="$router.push('/offers/' + item._id)"
                      style="cursor: pointer"
                    >
                      {{ $t("Shop now") }}
                    </button>
                  </div>
                </div>
              </div>
              <img
                :src="$baseUploadURL + item.offerImage"
                :alt="$getByLang(item.name)"
              />
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="new mt-5 mb-3 mb-md-5 container">
      <h3 class="mb-3 mb-md-5">
        {{ $t("recently arrived") }}
      </h3>
      <!-- Slider main container -->
      <div ref="newSwiper" class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->

          <news-arrived
            :newItems="newItems"
            :locale="locale"
            @addLike="addLike"
          />
          <!-- <div
            class="swiper-slide"
            v-for="(item, index) of newItems"
            :key="index"
          >
            <div class="newCard">
              <img
                v-if="item.imLikedThis"
                src="/images/unlike.svg"
                class="like"
                @click="addLike(item)"
                :alt="$getByLang(item.name)"
              />
              <img
                v-else
                src="/images/like.svg"
                class="like"
                @click="addLike(item)"
                :alt="$getByLang(item.name)"
              />

              <img
                class="logo"
                :src="
                  item.colorsList.length > 0 &&
                  item.colorsList[0].colorsImage.length > 0
                    ? $baseUploadURL + item.colorsList[0].colorsImage[0].name
                    : ''
                "
                :alt="$getByLang(item.name)"
                style="cursor: pointer"
                @click="$router.push('/product/' + item._id)"
              />
              <div
                class="newCard-body px-2"
                style="cursor: pointer"
                @click="$router.push('/product/' + item._id)"
              >
                <h5 class="text-truncate">
                  {{ $textSlice($getByLang(item.name), 40) }}
                </h5>
                <div class="d-flex align-items-center gap-2">
                  <b-form-rating
                    v-model="item.rate"
                    variant="warning"
                    readonly
                    inline
                    no-border
                    size="sm"
                    :locale="locale"
                  ></b-form-rating>
                  <span> ({{ item.rateAmount }}) </span>
                </div>

                <p class="mb-2">
                  {{ item.sizes.length > 0 ? item.sizes[0].mainPrice : 0 }}
                  {{ $t("r.s") }}
                </p>
              </div>
            </div>
          </div> -->
        </div>

        <!-- If we need scrollbar -->
        <!-- <div class="swiper-scrollbar"></div> -->
        <!-- If we need navigation buttons -->
        <!-- <div class="swiper-button justify-content-end mt-2">
          <div class="cusom-swiper-button-next">
            <img src="../assets/images/next.svg" alt="" class="dg180" />
          </div>
          <div class="cusom-swiper-button-prev">
            <img src="../assets/images/prev.svg" alt="" class="dg180" />
          </div>
        </div> -->
      </div>
    </section>

    <div ref="xxxxtfftft"></div>
    <!-- <section
      class="shoping mt-5 container"
      v-for="(item, index) of subCatList"
      :key="index"
    >
      <h3 class="mb-3 mb-md-5">
        {{ $t("Shop at") }} {{ $getByLang(item.name) }}
        <span
          @click="$router.push('/products/' + item._id)"
          style="cursor: pointer"
        >
          {{ $t("Watch more") }}
        </span>
      </h3>

      <div class="container">
        <div class="row">
          <div class="col-md-3 col-12 mb-3 mb-lg-0">
            <div class="box">
              <div class="d-flex flex-column justify-content-between h-100">
                <div class="">
                  <h3>{{ $t("Shop at") }}</h3>
                  <h2>{{ $getByLang(item.name) }}</h2>
                  <p v-if="item.subCategoryInfo">
                    {{ $getByLang(item.subCategoryInfo) }}
                  </p>
                </div>
                <div
                  class="btn-logo d-flex align-items-center justify-content-between"
                >
                  <div class="swiper-button">
                    <div
                      class="cusom-swiper-button-next-d-2"
                      :class="'x-next-' + item._id"
                    >
                      <img
                        src="../assets/images/next.svg"
                        class="dg180"
                        alt=""
                      />
                    </div>
                    <div
                      class="cusom-swiper-button-prev-d-2"
                      :class="'x-prev-' + item._id"
                    >
                      <img
                        src="../assets/images/prev.svg"
                        class="dg180"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="image" style="opacity: 85%">
                    <img src="../assets/demo/black/black-logo.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9 col-12">
            <div class="slider">

              <div :ref="'shopingSwiper' + item._id" class="swiper">
                <div class="swiper-wrapper">
                  <div
                    v-for="(itm, index) of item.items"
                    :key="index"
                    class="swiper-slide chair-slider"
                    @click="$router.push('/product/' + itm._id)"
                  >
                    <img
                      :src="
                        itm.colorsList.length > 0 &&
                        itm.colorsList[0].colorsImage.length > 0
                          ? $baseUploadURL +
                            itm.colorsList[0].colorsImage[0].name
                          : ''
                      "
                      :alt="$getByLang(itm.name)"
                    />
                  :src="
                    itm.colorsList.length > 0 &&
                    itm.colorsList[0].colorsImage.length > 0
                      ? $baseUploadURL + itm.colorsList[0]?.colorsImage[0]?.name
                      : ''
                  "

                    <button class="position-absolute">
                      {{ $textSlice($getByLang(itm.name), 20) }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section v-if="packages.length >0" class="packages mt-5 mb-3 mb-md-5 container">
      <h3 class="mb-3 mb-md-5">
        {{ $t("Packages") }}
        <span @click="$router.push('/packages')" style="cursor: pointer">
          {{ $t("Watch more") }}
        </span>
      </h3>
      <div class="container">
        <div class="row">
          <div
            class="col-md-4 mb-4 mb-md-0"
            v-for="(item, index) of packages"
            :key="index"
          >
            <div
              class="pack"
              @click="$router.push('/package/' + item._id)"
              style="cursor: pointer"
            >
              <div class="d-flex w-100 flex-row-reverse justify-content-end">
                <img
                  v-for="(src, i) in item.packageImages"
                  :key="i"
                  v-if="i < 4"
                  :src="$baseUploadURL + src.name"
                  alt=""
                />
              </div>
              <div
                class="w-100 mt-3 d-flex align-items-end justify-content-between"
              >
                <div class="">
                  <h3 class="mb-2 fw-bold">{{ $getByLang(item.name) }}</h3>
                  <p class="mb-0">{{ item.itemsCount }} {{ $t("Elements") }}</p>
                </div>
                <div class="price">{{ item.price }}{{ $t("r.s") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-show="vendorsList.length > 0" class="shops my-5 container">
      <h3 class="mb-1">
        {{ $t("Shops") }}
        <span @click="$router.push('/allshops')" style="cursor: pointer">
          {{ $t("Watch more") }}
        </span>
      </h3>
      <!-- Slider main container -->
      <div ref="shopsSwiper" class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div
            class="swiper-slide border rounded p-2"
            v-for="(item, index) of vendorsList"
            :key="index"
            @click="$router.push('/shops/' + item._id)"
            style="cursor: pointer; display: inline-block"
          >
            <img
              :src="$baseUploadURL + item.vendorImage"
              :alt="item.name"
              style="width: 100%"
            />

            <!-- <p>{{ item.name }}</p> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Scrollbar } from "swiper/modules";
// import Swiper and modules styles
import "swiper/swiper.css";
import "swiper/modules/navigation.min.css";
import "swiper/modules/scrollbar.min.css";
import CategorySlider from "./homeComponents/CategorySlider.vue";
import NewsArrived from "./homeComponents/NewsArrived.vue";
import OffersSlider from "./homeComponents/OffersSlider.vue";

export default {
  components: {
    NewsArrived,
    CategorySlider,
    OffersSlider,
  },
  data() {
    const lang = localStorage.lang || "ar";

    return {
      value: 4.567,
      locale: "ar-EG",
      catList: [],
      vendorsList: [],
      newItems: [],
      packages: [],
      subCatList: [],
      sliderList: [],
      offersList: [],
      oneTime: false,
      lang,
    };
  },
  methods: {
    isTab() {
      var windowWidth = window.innerWidth;

      return windowWidth < 955;
    },
    isMob() {
      var windowWidth = window.innerWidth;

      return windowWidth < 750;
    },

    async setSubCat(id) {
      if (this.oneTime) {
        const el = await this.$refs.xxxxtfftft;
        await el.scrollIntoView({ behavior: "smooth" });
      }
      this.oneTime = true;
      // this.subCatList = [];
      const dir = this.lang == "en" ? "ltr" : "rtl";
      const res = await this.$http.get("subCategories/byItems/" + id);

      this.subCatList = await res.data;

      setTimeout(() => {
        for (const key in this.$refs) {
          if (Object.hasOwnProperty.call(this.$refs, key)) {
            const element = this.$refs[key];
            if (key.startsWith("shopingSwiper")) {
              new Swiper(element[0], {
                loop: true,
                spaceBetween: 20,
                slidesPerView: this.isTab() ? 2.5 : 4,
                centeredSlides: false,
                freeMode: true,
                dir,
                modules: [Navigation],
                navigation: {
                  nextEl: ".x-next-" + key.replace("shopingSwiper", ""),
                  prevEl: ".x-prev-" + key.replace("shopingSwiper", ""),
                },
              });
            }
          }
        }
      }, 100);

      return await true;
    },
    addLike(item) {
      if (localStorage.homeHere) {
        this.$http
          .post("favourite", {
            itemId: item._id,
          })
          .then((res) => {
            item.imLikedThis = !item.imLikedThis;
            this.$swal({
              title: this.$t("Successfully Done"),
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: "Must be loged in",
          life: 3000,
        });
      }
    },
    byLang() {
      const dir = this.lang == "en" ? "ltr" : "rtl";
      new Swiper(this.$refs.swiper, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        slidesPerView: this.isTab() ? 2 : 4,
        // spaceBetween: 8,
        dir,
        modules: [Navigation],

        navigation: {
          nextEl: ".xsd-button-next",
          prevEl: ".xsd-button-prev",
        },
      });
      new Swiper(this.$refs.newOffers, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        slidesPerView: this.isTab() ? 2.5 : 4,
        // centeredSlides: true,
        spaceBetween: 10,
        freeMode: true,
        dir,
      });

      new Swiper(this.$refs.shopsSwiper, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        slidesPerView: this.isTab() ? 2.5 : 4,
        // centeredSlides: true,
        spaceBetween: 10,
        freeMode: true,
        dir,
      });

      new Swiper(this.$refs.newSwiper, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        // slidesPerView: this.isTab() ? (this.isMob() ? 1 : 2) : 4,
        slidesPerView: this.isTab() ? 2.5 : 4,

        spaceBetween: 10,
        // centeredSlides: true,
        // freeMode: true,
        dir,
        modules: [Navigation, Scrollbar],
        navigation: {
          nextEl: ".cusom-swiper-button-next",
          prevEl: ".cusom-swiper-button-prev",
        },
        // And if we need scrollbar
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },
      });
    },
  },
  mounted() {
    this.byLang();

    this.$eventHub.$on("changeLang", () => {
      this.byLang();
      
      
    });

    this.$http.get("categories?limit=10000").then(async (res) => {
      this.catList = await res.data.docs;
      sessionStorage.removeItem("homeHerePageAllshops")
      sessionStorage.removeItem("homeHerePageNumberAllShops")

      sessionStorage.removeItem("homeHerePageCategories")
      sessionStorage.removeItem("homeHerePageNumberCategories")

      sessionStorage.removeItem("homeHerePageOffer")
      sessionStorage.removeItem("homeHerePageNumberOffer")

      sessionStorage.removeItem("homeHerePagePackages")
      sessionStorage.removeItem("homeHerePageNumberPackages")

      sessionStorage.removeItem("homeHerePageProducts")
      sessionStorage.removeItem("homeHerePageNumberProducts")

      sessionStorage.removeItem("homeHerePageSearch")
      sessionStorage.removeItem("homeHerePageNumberSearch")

      sessionStorage.removeItem("homeHerePageShops")
      sessionStorage.removeItem("homeHerePageNumberShops")

      sessionStorage.removeItem("homeHerePageMyfavorite")
      sessionStorage.removeItem("homeHerePageNumberMyfavorite")
      if (this.catList.length > 0) {
        await this.setSubCat(this.catList[0]._id);
      }
    });

    // this.$http.get("categories?limit=10000").then(async (res) => {
    //   this.catList = await res.data.docs;
    //   // if (this.catList.length > 0) {
    //   //   await this.setSubCat();
    //   // }
    // });
    this.$http.get("vendors?limit=10000").then((res) => {
      this.vendorsList = res.data.docs.filter((li) => li.showInHome == true);
    });

    // this.$http.post("subCategories/findAllData").then(async (res) => {
    //   this.subCatList = await res.data;
    //   this.setSubCat();
    //   // for (let index = 0; index < this.subCatList.length; index++) {
    //   //   const element = this.subCatList[index];
    //   //   this.setSubCat(element._id);
    //   // }
    // });

    this.$http.get("items?limit=16").then((res) => {      
      this.newItems = res.data.docs;
      console.log();
      
    });
    this.$http.get("packages?limit=4").then((res) => {
      this.packages = res.data.docs;
    });

    this.$http.get("slider?limit=10000").then((res) => {
      this.sliderList = res.data.docs.filter(li=>li.imageAr != null);
      
    });

    // const date = this.$moment(new Date()).format("MM/DD/YYYY");
    this.$http
      .post("offers/getValidOffersToday?limit=10000", {
        // date: date,
      })
      .then((res) => {
        this.offersList = res.data.docs;
      });
  },
  // created() {}
};
</script>

<style lang="scss">
.cusom-swiper-button-next,
.cusom-swiper-button-prev {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.cusom-swiper-button-next-d-2,
.cusom-swiper-button-prev-d-2 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  img {
    width: 16px;
  }
}

.swiper-button {
  width: 100%;
}
.shoping .swiper-slide button {
  display: none;
}
.shoping .swiper-slide:hover button {
  display: block;
  animation: introtop;
  animation-duration: 0.8s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  width: 75%;
}
.shoping .swiper-slide:hover img {
  box-shadow: 0px 4px 50px 0px #0000001a;

  animation: introtop;
  animation-duration: 0.8s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
@media (max-width: 960px) {
  .shoping .swiper-slide:hover img {
    animation: introtop;
    animation-duration: 0.8s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
}
</style>
